const events = [
  {
    title: "Pio Monte della Misericordia",
    date: "03/25/2022",
    dateText: "",
    time: "18:00",
  },
  {
    title: "Pio Monte della Misericordia",
    date: "03/27/2022",
    dateText: "",
    time: "18:00",
  },

  {
    title: "Pio Monte della Misericordia",
    date: "03/11/2022",
    dateText: "",
    time: "18:00",
  },

  {
    title: "Pio Monte della Misericordia",
    date: "01/25/2022",
    dateText: "",
    time: "18:00",
  },
  {
    title: "Pio Monte della Misericordia",
    date: "05/25/2022",
    dateText: "",
    time: "18:00",
  },
  {
    title: "Pio Monte della Misericordia",
    date: "01/25/2023",
    dateText: "",
    time: "18:00",
  },
  {
    title: "Pio Monte della Misericordia",
    date: "02/25/2023",
    dateText: "",
    time: "18:00",
  },
  {
    title: "Pio Monte della Misericordia",
    date: "05/25/2023",
    dateText: "",
    time: "18:00",
  },
  {
    title: "Pio Monte della Misericordia",
    date: "04/25/2023",
    dateText: "",
    time: "18:00",
  },
  {
    title: "Pio Monte della Misericordia",
    date: "08/25/2023",
    dateText: "",
    time: "18:00",
  },
];

export default events;
