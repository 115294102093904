import "./style.css";
import layout from "./layout.html";
import { randomArbitrary, randomInt } from "@utils/math";

export default class Dots {
  static selector = ".dots";

  constructor(block) {
    this.block = block;
    this.block.innerHTML = layout;
    this.dotTemplate = block.querySelector("#dot-template");
    this.timerDuration = 30000;
    this.scaleMin = 0.4;
    this.scaleMax = 1;
    this.maxDotsNumbers = 50;
    this.dotsItemsCount = 0;
  }

  clearBlock = () => {
    this.block.innerHTML = "";
  };

  generateDot = () => {
    if (this.dotsItemsCount >= this.maxDotsNumbers) {
      clearInterval(this.interval);
      return;
    }
    const dotNode = this.dotTemplate.content.firstElementChild.cloneNode(true);
    const dotX = randomInt(window.innerWidth);
    const dotY = randomInt(window.innerHeight);
    const dotScale = randomArbitrary(this.scaleMin, this.scaleMax);
    dotNode.style.left = `${dotX}px`;
    dotNode.style.top = `${dotY}px`;
    dotNode.style.transform = `translate3d(-50%, -50%, 0) scale(${dotScale})`;
    this.block.appendChild(dotNode);
    this.dotsItemsCount += 1;
  };

  startTimer = () => {
    this.timeout = setTimeout(() => {
      this.block.classList.add("dots--visible");
      this.interval = setInterval(this.generateDot, 1600);
    }, this.timerDuration);
  };

  onUserInteraction = () => {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    if (this.interval) {
      clearInterval(this.interval);
      this.block.classList.remove("dots--visible");
      this.clearBlock();
      this.dotsItemsCount = 0;
    }

    this.startTimer();
  };

  onReady = () => {
    return new Promise((resolve, reject) => {
      this.mounted = true;
      window.addEventListener("mousemove", this.onUserInteraction);
      window.addEventListener("wheel", this.onUserInteraction);
      window.addEventListener("touchmove", this.onUserInteraction);
      this.startTimer();

      resolve();
    });
  };
}
