import "./style.css";
import layout from "./layout.html";
import { getScroll, getRaf } from "@app";
import { clamp, map } from "@utils/math";
import works from "@data/paperbarWorks";
import { laptop } from "@app/breakpoints";

export default class PaperbarWorks {
  static selector = ".paperbar";

  constructor(block) {
    this.block = block;
    this.block.innerHTML = layout;
    this.itemsNode = block.querySelector(".paperbar__items");
    this.itemsWrapperNode = block.querySelector(".paperbar__items__wrapper");
    this.worksNode = block.querySelector(".paperbar__works");
    this.workTemplate = block.querySelector("#work-template");

    this.animations = {
      itemsTranform: {
        progress: 0,
        node: this.itemsWrapperNode,
        styles: {
          transform: {
            style: () => {
              const value =
                (this.maxOffset / 4) *
                this.animations.itemsTranform.styles.transform.current *
                -1;

              return `translate3d(${value}px, 0, 0)`;
            },
            dampingFactor: 0.09,
            toValue: 0,
            fromValue: 0,
            current: 0,
            setValue: () => {
              this.animations.itemsTranform.styles.transform.fromValue =
                this.animations.itemsTranform.styles.transform.fromValue +
                (this.animations.itemsTranform.styles.transform.toValue -
                  this.animations.itemsTranform.styles.transform.fromValue) *
                  this.animations.itemsTranform.styles.transform.dampingFactor;

              return this.animations.itemsTranform.styles.transform.fromValue;
            },
          },
        },
      },
    };
  }

  setupWork = (work) => {
    let workNode = this.workTemplate.content.firstElementChild.cloneNode(true);
    if (workNode) {
      let workImage = workNode.querySelector("img");
      workImage.src = work.cover;

      return workNode;
    } else {
      return null;
    }
  };

  setupWorks = () => {
    return new Promise(async (resolve, reject) => {
      works.forEach((work, index) => {
        const workNode = this.setupWork(work);
        if (workNode) {
          this.worksNode.appendChild(workNode);
        }
      });

      resolve();
    });
  };

  updateProgress = (scrollItem, scroll) => {
    const progress = clamp(map(scrollItem.progress, 0.02, 0.98, 0, 1), 0, 1);
    this.animations.itemsTranform.styles.transform.toValue = progress;
  };

  layout = () => {
    for (const key1 in this.animations) {
      for (const key2 in this.animations[key1].styles) {
        this.animations[key1].node.style[key2] =
          this.animations[key1].styles[key2].style();
      }
    }
  };

  render = () => {
    for (const key1 in this.animations) {
      for (const key2 in this.animations[key1].styles) {
        this.animations[key1].styles[key2].current =
          this.animations[key1].styles[key2].setValue();
      }
    }

    this.layout();
  };

  onReady = () => {
    return new Promise(async (resolve, reject) => {
      this.mounted = true;
      await this.setupWorks();
      await this.onResize();
      this.scroll = getScroll();
      this.scroll.register("paperbar__items", this.updateProgress);

      if (!this.raf) {
        this.raf = getRaf();
        this.raf.register(this.block.dataset.instanceIndex, this.render);
      }

      resolve();
    });
  };

  onResize = () => {
    return new Promise(async (resolve, reject) => {
      this.windowSizes = {
        width: window.innerWidth,
        height: window.innerHeight,
      };

      if (this.windowSizes.width >= laptop) {
        this.maxOffset =
          (this.itemsNode.scrollWidth - this.windowSizes.width) * 4;
        this.block.style.height = `${this.maxOffset}px`;
      }

      resolve();
    });
  };
}
