import "./style.css";
import layout from "./layout.html";

export default class IntroText {
  static selector = ".intro-text";

  constructor(block) {
    this.block = block;

    this.block.innerHTML = layout;
  }
}
