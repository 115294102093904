import "./style.css";
import layout from "./layout.html";

export default class PageTransitioner {
  static selector = ".page-transitioner";

  constructor(block) {
    this.block = block;
    this.block.innerHTML = layout;

    this.transitionDuration = 600;

    this.showCallbacks = [];
    this.hideCallbacks = [];
  }

  subscribeOnShow = (callback) => {
    this.showCallbacks.push(callback);
  };

  subscribeOnHide = (callback) => {
    this.hideCallbacks.push(callback);
  };

  show = () => {
    return new Promise((resolve, reject) => {
      this.block.classList.add("page-transitioner--visible");

      setTimeout(() => {
        this.shown();
        resolve();
      }, this.transitionDuration);
    });
  };

  shown = () => {
    this.showCallbacks.forEach((callback) => {
      callback();
    });
    this.showCallbacks = [];
  };

  hide = () => {
    return new Promise((resolve, reject) => {
      this.block.classList.remove("page-transitioner--visible");

      setTimeout(() => {
        this.hidden();
        resolve();
      }, this.transitionDuration);
    });
  };

  hidden = () => {
    this.hideCallbacks.forEach((callback) => {
      callback();
    });
    this.hideCallbacks = [];
  };

  onReady = () => {};
}
