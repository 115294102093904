import "./style.css";
import layout from "./layout.html";
import { getScroll, getRaf, getInstance } from "@app";
import { clamp, map } from "@utils/math";
import events from "@data/events";
import { laptop } from "@app/breakpoints";

export default class Events {
  static selector = ".events";

  constructor(block) {
    this.block = block;
    this.block.innerHTML = layout;
    this.yearTemplate = block.querySelector("#year-template");
    this.eventTemplate = block.querySelector("#event-template");
    this.monthTemplate = block.querySelector("#month-template");
    /*this.items = block.querySelector(".works__items");
    this.itemsWrapper = block.querySelector(".works__items__wrapper");
    this.itemNodes = block.querySelectorAll(".works__items__wrapper__item");
    this.workTemplate = block.querySelector("#work-template");
    this.workTitleTemplate = block.querySelector("#work-title-template");
    this.titlesNode = block.querySelector(".works__titles");
    this.titleNodes = [];
    this.titleNodeInstances = [];

    this.animations = {
      itemsTranform: {
        progress: 0,
        node: this.itemsWrapper,
        styles: {
          transform: {
            style: () => {
              const value =
                (this.maxOffset / 4) *
                this.animations.itemsTranform.styles.transform.current *
                -1;

              return `translate3d(${value}px, 0, 0)`;
            },
            dampingFactor: 0.09,
            toValue: 0,
            fromValue: 0,
            current: 0,
            setValue: () => {
              this.animations.itemsTranform.styles.transform.fromValue =
                this.animations.itemsTranform.styles.transform.fromValue +
                (this.animations.itemsTranform.styles.transform.toValue -
                  this.animations.itemsTranform.styles.transform.fromValue) *
                  this.animations.itemsTranform.styles.transform.dampingFactor;

              return this.animations.itemsTranform.styles.transform.fromValue;
            },
          },
        },
      },
    };*/
  }

  /*setupTitle = (work) => {
    let titleNode =
      this.workTitleTemplate.content.firstElementChild.cloneNode(true);
    if (titleNode) {
      titleNode.textContent = work.title;

      return titleNode;
    } else {
      return null;
    }
  };

  setupWork = (work) => {
    let workNode = this.workTemplate.content.firstElementChild.cloneNode(true);
    if (workNode) {
      let workImage = workNode.querySelector("img");
      workImage.src = work.cover;

      return workNode;
    } else {
      return null;
    }
  };

  setupWorks = () => {
    return new Promise(async (resolve, reject) => {
      works.forEach((work, index) => {
        const workNode = this.setupWork(work);
        workNode.dataset.workIndex = index;
        workNode.addEventListener("mouseenter", this.onWorkMouseEnter);
        workNode.addEventListener("mouseleave", this.onWorkMouseLeave);
        if (workNode) {
          this.itemsWrapper.appendChild(workNode);
        }

        const titleNode = this.setupTitle(work);
        if (titleNode) {
          this.titlesNode.appendChild(titleNode);
          this.titleNodes.push(titleNode);
        }
      });

      resolve();
    });
  };

  updateProgress = (scrollItem, scroll) => {
    const progress = clamp(map(scrollItem.progress, 0.02, 0.98, 0, 1), 0, 1);
    this.animations.itemsTranform.styles.transform.toValue = progress;
  };

  layout = () => {
    for (const key1 in this.animations) {
      for (const key2 in this.animations[key1].styles) {
        this.animations[key1].node.style[key2] =
          this.animations[key1].styles[key2].style();
      }
    }
  };

  render = () => {
    for (const key1 in this.animations) {
      for (const key2 in this.animations[key1].styles) {
        this.animations[key1].styles[key2].current =
          this.animations[key1].styles[key2].setValue();
      }
    }

    this.layout();
  };*/

  setupEventsData = () => {
    return new Promise(async (resolve, reject) => {
      this.events = {};
      events.forEach((event) => {
        const timestamp = Date.parse(event.date);
        event.timestamp = timestamp;

        const date = new Date(timestamp);
        event.jsDate = date;

        const year = date.getFullYear();
        if (!this.events[year]) {
          this.events[year] = {};
        }

        const month = date.getMonth();
        if (!this.events[year][month]) {
          this.events[year][month] = [];
        }

        this.events[year][month].push(event);
      });

      resolve();
    });
  };

  setupEvent = (event) => {
    let eventNode =
      this.eventTemplate.content.firstElementChild.cloneNode(true);
    if (eventNode) {
      let titleNode = eventNode.querySelector(
        ".events__year__body__months__month__events__event__title"
      );
      titleNode.textContent = event.title;

      let dateNode = eventNode.querySelector(
        ".events__year__body__months__month__events__event__date"
      );
      dateNode.textContent = `${
        event.jsDate.getMonth() + 1
      }/${event.jsDate.getDate()}`;

      return eventNode;
    } else {
      return null;
    }
  };

  updateMonth = (scrollItem, scroll, calendarMiddleTextNode) => {
    if (
      scroll.y + this.windowSizes.height / 4 >= scrollItem.top &&
      scroll.y + this.windowSizes.height / 4 <= scrollItem.bottom
    ) {
      const monthText = scrollItem.el.dataset.monthText;
      calendarMiddleTextNode.dataset.calendarMonth = monthText;
    }
  };

  setupMonth = (year, month, calendarMiddleTextNode) => {
    const date = new Date(2023, month);
    const monthText = date.toLocaleString("default", { month: "long" });

    let monthNode =
      this.monthTemplate.content.firstElementChild.cloneNode(true);
    if (monthNode) {
      let title = monthNode.querySelector(
        ".events__year__body__months__month__title"
      );
      title.textContent = monthText;

      monthNode.dataset.monthText = monthText;
      monthNode.dataset.scrollId = `${year}_${month}`;
      this.scroll.register(`${year}_${month}`, (scrollItem, scroll) => {
        this.updateMonth(scrollItem, scroll, calendarMiddleTextNode);
      });

      return monthNode;
    } else {
      return null;
    }
  };

  setupYear = (year) => {
    let yearNode = this.yearTemplate.content.firstElementChild.cloneNode(true);
    if (yearNode) {
      let title = yearNode.querySelector(".events__year__title .p6");
      title.textContent = year;
      title.dataset.scrollTarget = `#event-year-${year}`;

      let yearBodyNode = yearNode.querySelector(".events__year__body");
      yearBodyNode.id = `cw-${year}`;

      let calendarNode = yearBodyNode.querySelector(
        ".events__year__body__calendar"
      );
      calendarNode.dataset.scrollTarget = `#cw-${year}`;
      calendarNode.id = `c-${year}`;

      let calendarStartNode = yearNode.querySelector(
        ".events__year__body__calendar__text--start"
      );
      calendarStartNode.textContent = year;

      let calendarEndNode = yearNode.querySelector(
        ".events__year__body__calendar__text--end"
      );
      calendarEndNode.textContent = `${parseInt(year, 10) + 1}`;

      let calendarMiddleNode = yearNode.querySelector(
        ".events__year__body__calendar__text--middle"
      );
      calendarMiddleNode.dataset.scrollTarget = `#c-${year}`;

      return yearNode;
    } else {
      return null;
    }
  };

  setupEvents = () => {
    return new Promise(async (resolve, reject) => {
      for (const year in this.events) {
        const yearNode = this.setupYear(year);
        if (yearNode) {
          yearNode.id = `event-year-${year}`;
          this.block.appendChild(yearNode);

          for (const month in this.events[year]) {
            const calendarMiddleTextNode = yearNode.querySelector(
              ".events__year__body__calendar__text--middle p"
            );
            const monthNode = this.setupMonth(
              year,
              month,
              calendarMiddleTextNode
            );
            if (monthNode) {
              const orderedEvents = this.events[year][month].sort(
                (a, b) => a.timestamp - b.timestamp
              );
              orderedEvents.forEach((event) => {
                const eventNode = this.setupEvent(event);
                if (eventNode) {
                  const eventsNode = monthNode.querySelector(
                    ".events__year__body__months__month__events"
                  );
                  if (eventsNode) {
                    eventsNode.appendChild(eventNode);
                  }
                }
              });

              const monthsNode = yearNode.querySelector(
                ".events__year__body__months"
              );
              if (monthsNode) {
                monthsNode.appendChild(monthNode);
              }
            }
          }
        }
      }

      resolve();
    });
  };

  onReady = () => {
    return new Promise(async (resolve, reject) => {
      this.mounted = true;
      //await this.setupWorks();
      await this.onResize();
      this.scroll = getScroll();
      //this.scroll.register("works__items", this.updateProgress);
      await this.setupEventsData();
      await this.setupEvents();

      if (!this.raf) {
        this.raf = getRaf();
        //this.raf.register(this.block.dataset.instanceIndex, this.render);
      }

      resolve();
    });
  };

  onComplete = () => {
    return new Promise(async (resolve, reject) => {
      resolve();
    });
  };

  onResize = () => {
    return new Promise(async (resolve, reject) => {
      this.windowSizes = {
        width: window.innerWidth,
        height: window.innerHeight,
      };

      resolve();
    });
  };
}
