import "./style.css";
import layout from "./layout.html";

export default class AboutIntro {
  static selector = ".about__intro";

  constructor(block) {
    this.block = block;

    this.block.innerHTML = layout;
  }
}
