import "./style.css";
import layout from "./layout.html";
import { getScroll } from "@app";

export default class ScrollDown {
  static selector = ".scroll-down";

  constructor(block) {
    this.block = block;

    this.block.innerHTML = layout;
  }

  onScrollPositionChanged = (scroll) => {
    const { y } = scroll;
    this.block.classList.toggle("scroll-down--hidden", y > 1);
  };

  onReady = () => {
    return new Promise((resolve) => {
      this.mounted = true;
      this.scroll = getScroll();
      this.scroll.registerOnScrollPositionChange(this.onScrollPositionChanged);
      resolve();
    });
  };
}
