import "./style.css";
import layout from "./layout.html";
import { getInstance } from "@app";

export default class Nav {
  static selector = ".nav";

  constructor(block) {
    this.block = block;
    this.block.innerHTML = layout;
    this.navItemNodes = block.querySelectorAll(".nav__menu__item a");
    this.animatedTexts = block.querySelectorAll(".animated-text");

    this.animatedTextsInstances = [];
    this.opened = false;
    this.duration = 600;
  }

  updateActiveNavLink = () => {
    const route = window.location.pathname.replace(/\/$/, "");
    this.navItemNodes &&
      this.navItemNodes.forEach((node) => {
        node.classList.toggle(
          "underlineable--active",
          node.pathname.replace(/\/$/, "") === route.replace(/\/$/, "")
        );
      });
  };

  hide = () => {
    if (this.socials) {
      this.socials.style.opacity = null;
    }
    this.animatedTextsInstances.forEach((instance) => {
      if (instance.hide) {
        instance.hide();
      }
    });

    this.block.style.pointerEvents = null;

    setTimeout(() => {
      document.body.classList.remove("nav-visible");
    }, this.duration / 4);
  };

  show = () => {
    document.body.classList.add("nav-visible");

    setTimeout(() => {
      this.animatedTextsInstances.forEach((instance) => {
        if (instance.show) {
          instance.show();
        }
      });
    }, this.duration / 2);
  };

  changeState = () => {
    if (!this.opened) {
      this.show();
    } else {
      this.hide();
    }

    this.opened = !this.opened;
  };

  onToggleNavClicked = () => {
    this.changeState();
  };

  onComplete = () => {
    return new Promise(async (resolve, reject) => {
      this.completed = true;

      this.updateActiveNavLink();

      this.toggleButtons = document.querySelectorAll(".toggle-nav");

      this.toggleButtons.forEach((button) => {
        button.addEventListener("click", this.onToggleNavClicked);
      });

      if (this.animatedTexts) {
        this.animatedTexts.forEach((node) => {
          const instance = getInstance(node);

          if (instance) {
            this.animatedTextsInstances.push(instance);
          }
        });
      }

      resolve();
    });
  };

  onPageChangeComplete = () => {
    return new Promise(async (resolve, reject) => {
      this.updateActiveNavLink();
      resolve();
    });
  };
}
