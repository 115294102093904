import { listen } from "./History.js";
import { getInstance, getScroll } from "/index.js";

export default class Router {
  constructor() {
    this.req = new XMLHttpRequest();
    this.currentRoute = window.location.pathname;
    this.currentHash = window.location.hash;
    this.pageTransitionerNode = document.querySelector(".page-transitioner");

    listen((source, destination) => {
      //window.App.setIsPageChanging(true);
      this.startLoad(source, destination);
    });

    window.addEventListener("popstate", this.onPopState);
  }

  onPopState = () => {
    this.startLoad(
      this.currentRoute,
      window.location.pathname.replace(/\/$/, "")
    );
  };

  startLoad(source, destination) {
    const hashIndex = destination.indexOf("#");
    if (hashIndex > 0) {
      destination = destination.substring(0, hashIndex - 1);
    }

    /*    if (destination.length < 1) {
      window.location.reload();
      return;
    }
*/
    const navVisible = document.body.classList.contains("nav-visible");

    if (navVisible) {
      document.body.classList.add("no-transitions");

      this.getContentFrom(destination, source);
    } else {
      const pageTransitioner = getInstance(this.pageTransitionerNode);
      pageTransitioner.subscribeOnShow(() => {
        this.getContentFrom(destination, source);
      });

      pageTransitioner.show();
    }
  }

  getContentFrom(destination, source) {
    if (this.req != null) {
      this.req.open("GET", `${destination}/`, true);
      this.req.onreadystatechange = () => {
        this.newContentReceived(destination, source);
      };
      this.req.responseType = "document";

      this.req.send();
    } else {
    }
  }

  newContentReceived = async (destination, source) => {
    if (this.req.readyState == 4 && this.req.status == 200) {
      const receivedDocument = this.req.responseXML;

      // Update Page Title
      document.title = receivedDocument.title;

      // Update main classes
      const receivedMain = receivedDocument.querySelector("main");
      const main = document.querySelector("main");
      main.className = receivedMain.className;

      // Update main scroll attributes
      if (receivedMain.dataset.direction) {
        main.dataset.direction = receivedMain.dataset.direction;
      }

      if (receivedMain.dataset.scrollContainer) {
        main.dataset.scrollContainer = receivedMain.dataset.scrollContainer;
      }

      // Update main content
      main.innerHTML = receivedMain.innerHTML;

      // Reset main instance
      main.removeAttribute("data-instance-index");

      // Reset body instance
      document.body.removeAttribute("data-instance-index");

      // Add Header if missing
      const receivedDocumentHeader = receivedDocument.querySelector("header");
      if (!document.querySelector("header") && receivedDocumentHeader) {
        const header = receivedDocumentHeader.cloneNode(true);
        document.body.insertBefore(header, main);
      }

      // Init items
      await window.App.initItems();

      // Init clones
      await window.App.initClones();

      window.App.initColors();

      // Update body classes
      const bodyClassList = [...document.body.classList];
      bodyClassList.forEach((item) => {
        if (item !== "page-loading" && item !== "loaded") {
          document.body.classList.remove(item);
        }
      });

      receivedDocument.body.classList.forEach((item) => {
        document.body.classList.add(item);
      });

      const scroll = getScroll();
      if (scroll) {
        scroll.scroll.scrollTo("top", { disableLerp: true, duration: 0 });
      }

      const headerNode = document.querySelector("header");
      if (headerNode) {
        const header = getInstance(headerNode);
        //header.updateActiveNavLink();
      }

      // Hide Nav if visible
      if (document.body.classList.contains("nav-visible")) {
        const navInstance = getInstance(document.querySelector(".nav"));
        if (navInstance && navInstance.hide) {
          navInstance.changeState(false);
          navInstance.updateActiveNavLink();
        }
      }

      const pageTransitioner = getInstance(this.pageTransitionerNode);
      await pageTransitioner.hide();
      if (scroll) {
        scroll.update();
      }
      await window.App.onPageChangeComplete();
    }
  };
}
