import "./style.css";
import layout from "./layout.html";
import { getScroll, getRaf } from "@app";
import { clamp, map } from "@utils/math";
import { laptop } from "@app/breakpoints";

export default class Intro {
  static selector = ".intro";

  constructor(block) {
    this.block = block;
    this.block.innerHTML = layout;

    this.itemsNode = block.querySelector(".intro__items");
    this.itemNodes = block.querySelectorAll(".intro__items__item");

    this.animations = {};

    this.itemNodes.forEach((node, index) => {
      this.animations[`word_${index}`] = {
        node: node,
        styles: {
          transform: {
            style: () => {
              const value =
                (this.itemsNode.sizes.height -
                  this.itemNodes[0].sizes.height * index) *
                this.animations[`word_${index}`].styles.transform.current *
                -1;

              return `translate3d(0, ${value}px, 0)`;
            },
            dampingFactor: 0.09,
            toValue: 0,
            fromValue: 0,
            current: 0,
            setValue: () => {
              this.animations[`word_${index}`].styles.transform.fromValue =
                this.animations[`word_${index}`].styles.transform.fromValue +
                (this.animations[`word_${index}`].styles.transform.toValue -
                  this.animations[`word_${index}`].styles.transform.fromValue) *
                  this.animations[`word_${index}`].styles.transform
                    .dampingFactor;

              return this.animations[`word_${index}`].styles.transform
                .fromValue;
            },
          },

          filter: {
            style: () => {
              const value =
                16 * this.animations[`word_${index}`].styles.filter.current;

              return `blur(${value}px`;
            },
            dampingFactor: 0.09,
            toValue: 0,
            fromValue: 0,
            current: 0,
            setValue: () => {
              this.animations[`word_${index}`].styles.filter.fromValue =
                this.animations[`word_${index}`].styles.filter.fromValue +
                (this.animations[`word_${index}`].styles.filter.toValue -
                  this.animations[`word_${index}`].styles.filter.fromValue) *
                  this.animations[`word_${index}`].styles.filter.dampingFactor;

              return this.animations[`word_${index}`].styles.filter.fromValue;
            },
          },
        },
      };
    });
  }

  updateProgress = (scrollItem, scroll) => {
    let progress = clamp(map(scrollItem.progress, 0.05, 0.9, 0, 1), 0, 1);
    progress = map(progress, 0, 1, 0, this.itemNodes.length);
    this.itemNodes.forEach((node, index) => {
      let value = map(progress, index, index + 1, 0, 1);
      value = clamp(value, 0, 1);
      this.animations[`word_${index}`].styles.transform.toValue = value;

      let filterValue = map(progress, index + 0.8, index + 1, 0, 1);
      filterValue = clamp(filterValue, 0, 1);
      this.animations[`word_${index}`].styles.filter.toValue = filterValue;
    });
  };

  layout = () => {
    for (const key1 in this.animations) {
      for (const key2 in this.animations[key1].styles) {
        this.animations[key1].node.style[key2] =
          this.animations[key1].styles[key2].style();
      }
    }
  };

  render = () => {
    for (const key1 in this.animations) {
      for (const key2 in this.animations[key1].styles) {
        this.animations[key1].styles[key2].current =
          this.animations[key1].styles[key2].setValue();
      }
    }

    this.layout();
  };

  onReady = () => {
    return new Promise(async (resolve, reject) => {
      this.mounted = true;
      await this.onResize();
      this.scroll = getScroll();
      this.scroll.register("intro", this.updateProgress);

      if (!this.raf) {
        this.raf = getRaf();
        this.raf.register(this.block.dataset.instanceIndex, this.render);
      }

      resolve();
    });
  };

  onResize = () => {
    return new Promise(async (resolve, reject) => {
      this.windowSizes = {
        width: window.innerWidth,
        height: window.innerHeight,
      };

      this.itemsNode.sizes = {
        height: this.itemsNode.offsetHeight,
      };
      this.itemNodes[0].sizes = { height: this.itemNodes[0].offsetHeight };

      this.maxOffset =
        this.windowSizes.width >= laptop
          ? this.itemNodes.length * this.windowSizes.height * 2
          : this.itemNodes.length * this.windowSizes.height;
      this.block.style.height = `${this.maxOffset}px`;

      resolve();
    });
  };
}
