import "./style.css";
import layout from "./layout.html";

export default class Splash {
  static selector = ".splash";

  constructor(block) {
    this.block = block;
    this.block.innerHTML = layout;
  }

  onReady = () => {
    return new Promise((resolve) => {
      this.mounted = true;

      resolve();
    });
  };
}
