const works = [
  {
    title: "work_1",
    cover: "https://picsum.photos/200/300",
  },
  {
    title: "work_2",
    cover: "https://picsum.photos/200/300",
  },
  {
    title: "work_3",
    cover: "https://picsum.photos/200/300",
  },
  {
    title: "work_4",
    cover: "https://picsum.photos/200/300",
  },
];

export default works;
