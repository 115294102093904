import LocomotiveScroll from "locomotive-scroll";
import { laptop } from "@app/breakpoints.js";

export default class Scroll {
  constructor() {
    this.scrollItems = [];
    this.directionChangeCallbacks = [];
    this.positionChangeCallbacks = [];
    this.scrollItemsInView = [];

    this.scroll = new LocomotiveScroll({
      el: document.querySelector("[data-scroll-container]"),
      smooth: true,
      getDirection: true,
      tablet: { smooth: true },
      smartphone: { smooth: true },
    });

    this.scroll.on("scroll", this.onScroll);
  }

  scrollTo = (target, options = {}) => {
    if (window.innerWidth < laptop) {
      window.scrollTo(0, 0);
    } else {
      this.scroll.scrollTo(target, options);
    }
  };

  update = () => {
    this.scroll.update();
  };

  onCall = (scrollID, way, object) => {
    this.scrollItemsInView.forEach((item) => {
      if (item.scrollID === scrollID) {
        item.callback(scrollID, way, object);
      }
    });
  };

  onScroll = (args) => {
    this.positionChangeCallbacks.forEach((callback) => {
      callback(args.scroll);
    });

    if (
      args.direction &&
      !this.stopped &&
      (args.direction !== this.direction || !this.direction)
    ) {
      this.direction = args.direction;

      this.directionChangeCallbacks.forEach((callback) => {
        callback(this.direction);
      });
    }

    this.scrollItems.forEach((item) => {
      if (typeof args.currentElements[item.scrollID] === "object") {
        item.callback(args.currentElements[item.scrollID], args.scroll);
      }
    });
  };

  register = (scrollID, callback) => {
    this.scrollItems.push({
      scrollID: scrollID,
      callback: callback,
    });
  };

  registerOnInViewTrigger = (scrollID, callback) => {
    this.scrollItemsInView.push({
      scrollID: scrollID,
      callback: callback,
    });
  };

  registerOnScrollPositionChange = (callback) => {
    this.positionChangeCallbacks.push(callback);
  };

  registerOnScrollDirectionChange = (callback) => {
    this.directionChangeCallbacks.push(callback);
  };

  stop = () => {
    this.stopped = true;
    this.scroll.stop();
  };

  start = () => {
    this.stopped = false;
    this.scroll.start();
  };

  onComplete = () => {
    this.scroll.on("call", this.onCall);
  };
}
