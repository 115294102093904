const works = [
  {
    title: "Pio Monte della Misericordia",
    cover:
      "https://user-images.githubusercontent.com/41680436/229616121-e376a8e0-9c3c-4f22-91c8-9e6b4643956a.gif",
  },
  {
    title: "T4T",
    cover:
      "https://user-images.githubusercontent.com/41680436/227590668-17584ead-969b-4543-bd95-616ebe78ca5c.gif",
  },
  {
    title: "Habitart",
    cover:
      "https://user-images.githubusercontent.com/41680436/227491954-93ab5839-3d74-4072-a527-5e4dae1e1738.jpg",
  },
  {
    title: "Spot Home Gallery",
    cover:
      "https://user-images.githubusercontent.com/41680436/227491960-ce9b37b9-93b0-4de0-a250-69d9db2fcaa6.jpg",
  },
  {
    title: "Echino Design",
    cover:
      "https://user-images.githubusercontent.com/41680436/229616118-d808549b-54c2-45f4-9039-f36ef8a3f35d.gif",
  },
  {
    title: "Arte Pollino",
    cover:
      "https://user-images.githubusercontent.com/41680436/227491969-73a035da-d8db-4c82-99a2-6e05e6e14f75.jpg",
  },
  {
    title: "Istituto Caselli",
    cover:
      "https://user-images.githubusercontent.com/41680436/227491976-00670729-3046-4961-b2ff-87b684add95e.jpg",
  },
  {
    title: "Kiki Mou",
    cover:
      "https://user-images.githubusercontent.com/41680436/227590664-bc0b6c1c-e1ae-475a-8798-51bb44c37faf.gif",
  },
  {
    title: "Assaggi di Terre",
    cover:
      "https://user-images.githubusercontent.com/41680436/229616281-b7df376e-d436-450d-a4c3-21bb6d5d9f5a.jpg",
  },
];

export default works;
