import "./style.css";

export default class About {
  static selector = ".about";

  constructor(block) {
    this.block = block;
    this.mounted = false;
  }

  onReady = () => {
    this.mounted = true;
  };

  onPageChangeComplete = () => {
    this.onComplete();
  };

  onComplete = () => {};
}
