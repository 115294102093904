import "./style.css";
import layout from "./layout.html";
import { getRaf, getInstance, getScroll } from "@app";

export default class Cursor {
  static selector = ".cursor";

  constructor(block) {
    this.block = block;
    this.block.innerHTML = layout;
    this.circleNode = block.querySelector(".cursor__circle");

    this.animations = {
      transform: {
        style: () => {
          const x = this.animations.transform.styles.translateX.current;
          const y = this.animations.transform.styles.translateY.current;
          return `translate3d(calc(${x}px - 50%), calc(${y}px - 50%), 0)`;
        },
        styleKey: "transform",
        node: this.block,
        styles: {
          translateX: {
            dampingFactor: 1,
            deltaX: 0,
            deltaX0: 0,
            fromValue: 0,
            current: 0,
            setValue: () => {
              this.animations.transform.styles.translateX.deltaX0 =
                this.animations.transform.styles.translateX.deltaX0 +
                (this.animations.transform.styles.translateX.deltaX -
                  this.animations.transform.styles.translateX.deltaX0) *
                  this.animations.transform.styles.translateX.dampingFactor;

              return this.animations.transform.styles.translateX.deltaX0;
            },
          },

          translateY: {
            dampingFactor: 1,
            deltaY: 0,
            deltaY0: 0,
            fromValue: 0,
            current: 0,
            setValue: () => {
              this.animations.transform.styles.translateY.deltaY0 =
                this.animations.transform.styles.translateY.deltaY0 +
                (this.animations.transform.styles.translateY.deltaY -
                  this.animations.transform.styles.translateY.deltaY0) *
                  this.animations.transform.styles.translateY.dampingFactor;

              return this.animations.transform.styles.translateY.deltaY0;
            },
          },
        },
      },

      scale: {
        node: this.circleNode,
        styleKey: "transform",
        style: () => {
          const value = this.animations.scale.styles.scaleXY.current;
          return `translate3d(-50%, -50%, 0) scale(${value})`;
        },
        styles: {
          scaleXY: {
            dampingFactor: 0.09,
            toValue: 0.1481481481,
            fromValue: 0.1481481481,
            current: 0.1481481481,
            setValue: () => {
              this.animations.scale.styles.scaleXY.fromValue =
                this.animations.scale.styles.scaleXY.fromValue +
                (this.animations.scale.styles.scaleXY.toValue -
                  this.animations.scale.styles.scaleXY.fromValue) *
                  this.animations.scale.styles.scaleXY.dampingFactor;

              return this.animations.scale.styles.scaleXY.fromValue;
            },
          },
        },
      },
    };
    window.addEventListener("mousemove", this.onMouseMove);

    if (!this.raf) {
      this.raf = getRaf();
      this.raf.register("cursor", this.render);
    }
  }

  resetCursor = () => {
    this.animations.scale.styles.scaleXY.toValue = 0.5;
  };

  updateByTarget = () => {
    if (!this.target) {
      this.resetCursor();
      return;
    }

    const cursorNode = this.target.closest("[data-cursor]");
    if (cursorNode) {
      const cursorType = cursorNode.dataset.cursorType;

      switch (cursorType) {
        case "text":
          this.animations.scale.styles.scaleXY.toValue = 1;
          break;

        case "hidden":
          this.animations.scale.styles.scaleXY.toValue = 0;
          break;

        default:
          break;
      }
    } else {
      this.resetCursor();
    }
  };

  layout = () => {
    for (const key1 in this.animations) {
      for (const key2 in this.animations[key1].styles) {
        this.animations[key1].node.style[this.animations[key1].styleKey] =
          this.animations[key1].style();
      }
    }
  };

  render = () => {
    this.updateByTarget();

    for (const key1 in this.animations) {
      for (const key2 in this.animations[key1].styles) {
        this.animations[key1].styles[key2].current =
          this.animations[key1].styles[key2].setValue();
      }
    }

    this.layout();
  };

  mouseIntersectNode = (x, y, node) => {
    return (
      x >= node.sizes.left &&
      x <= node.sizes.right &&
      y > node.sizes.top &&
      y <= node.sizes.bottom
    );
  };

  getCursorNodes = () => {
    this.cursorNodes = document.querySelectorAll("[data-cursor]");
  };

  updateCursorNodesSizes = () => {
    this.cursorNodes.forEach((item) => {
      item.sizes = item.getBoundingClientRect();
    });
  };

  onMouseMove = (e) => {
    this.animations.transform.styles.translateX.deltaX = e.clientX;
    this.animations.transform.styles.translateY.deltaY = e.clientY;
    this.target = e.target;
  };

  onScroll = (e) => {
    const mouseX = this.animations.transform.styles.translateX.deltaX;
    const mouseY = this.animations.transform.styles.translateY.deltaY;
    const scrollY = e.y;

    this.target = null;
    this.cursorNodes.forEach((node) => {
      if (this.mouseIntersectNode(mouseX, mouseY + scrollY, node)) {
        this.target = node;
      }
    });
  };

  onReady = () => {
    return new Promise((resolve, reject) => {
      this.mounted = true;
      resolve();
    });
  };

  onComplete = () => {
    return new Promise(async (resolve, reject) => {
      this.completed = true;

      this.getCursorNodes();
      this.updateCursorNodesSizes();

      this.scroll = getScroll();
      this.scroll.registerOnScrollPositionChange(this.onScroll);

      resolve();
    });
  };

  onPageChangeComplete = () => {
    this.getCursorNodes();
    this.updateCursorNodesSizes();
  };

  onResize = () => {
    this.updateCursorNodesSizes();
  };

  onDestroy = () => {};
}
